<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="container" *ngIf="!loader">
  <div class="card first-card" style="flex: none; width: 35% !important">
    <div class="toggle-button">
      <div class="action-border">
        <div class="actions">
          {{ Prop.status == "START" ? "STARTING" : Prop.status }}
        </div>
      </div>
      <div class="success-count">
        <img src="/assets/images/checkmark.svg" alt="" />
        <span>{{ successSteps }}</span>
      </div>
      <div class="failed-count">
        <img src="/assets/images/remove_4.png" alt="" />
        <span>{{ failSteps }}</span>
      </div>
      <div class="duration">
        <span>{{ time }}</span>
      </div>
      <div
        *ngIf="Prop.status == 'RUNNING'"
        [matTooltip]="'Stop Execution...'"
        class="stop-execution"
      >
        <mat-icon (click)="stopExecutionevent()">pause</mat-icon>
      </div>
    </div>
    <div class="accordian-steps">
      <mat-accordion multi>
        <mat-expansion-panel hideToggle [expanded]="true">
          <mat-expansion-panel-header class="expansion-panel-custom">
            <mat-panel-title>
              {{ TestCase.name }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-panel-description> </mat-panel-description>
          <div
            [ngClass]="{
              'success-step': steps.executionData?.stepStatus == 'SUCCESS',
              'failed-step': steps.executionData?.stepStatus == 'FAILURE',
              'failed-step-section-main':
                steps.executionData?.stepStatus == 'FAILURE'
            }"
            *ngFor="let steps of TestSteps; let i = index"
          >
            <mat-expansion-panel
              hideToggle
              [expanded]="expandedIndex === i"
              (opened)="getStepDetailData(steps, i)"
            >
              <mat-expansion-panel-header class="expansion-panel-custom">
                <mat-panel-title>
                  <div
                    style="position: absolute; top: 2px; left: 53px"
                    *ngIf="steps.executionData?.stepStatus == 'RUNNING'"
                  >
                    <mat-progress-spinner
                      class="search-spinner"
                      mode="indeterminate"
                      diameter="20"
                    ></mat-progress-spinner>
                  </div>
                  <div class="steps-more-info">
                    <div class="sucessc-icon-with-text">
                      <mat-icon
                        class="success-step-indicator"
                        *ngIf="steps.executionData?.stepStatus == 'SUCCESS'"
                        >check_circle</mat-icon
                      >
                      <mat-icon
                        class="failed"
                        *ngIf="steps.executionData?.stepStatus == 'FAILURE'"
                        >cancel</mat-icon
                      >
                      <mat-icon
                        class="pending"
                        *ngIf="
                          steps.executionData?.stepStatus != 'SUCCESS' &&
                          steps.executionData?.stepStatus != 'FAILURE' &&
                          steps.executionData?.stepStatus != 'CONVERSATION'
                        "
                        >timer</mat-icon
                      >
                      <mat-icon
                        class="conversation"
                        *ngIf="
                          steps.executionData?.stepStatus === 'CONVERSATION'
                        "
                        >error</mat-icon
                      >
                      <span
                        [innerHtml]="steps.action"
                        class="fz-14"
                        [ngClass]="{
                          'truncate-text':
                            steps.executionData?.stepStatus === 'CONVERSATION',
                          'truncate-text2':
                            steps.executionData?.stepStatus !== 'CONVERSATION'
                        }"
                      ></span>
                    </div>
                  </div>
                </mat-panel-title>
                <mat-panel-description>
                  <div
                    class="ml-auto"
                    *ngIf="steps.executionData?.stepStatus === 'CONVERSATION'"
                  >
                    <button
                      class="theme-btn-clear-default py-3 px-10"
                      (click)="openLinkedTestPlansDialog(steps)"
                      style="color: white !important; width: 180px;"
                    >
                      User Action is Requested
                    </button>
                  </div>
                  <mat-icon *ngIf="steps.teststepResult?.hasImageDifference"
                    >visibility</mat-icon
                  >
                  <mat-icon style="color: white">keyboard_arrow_down</mat-icon>
                </mat-panel-description>
              </mat-expansion-panel-header>
              
              <mat-tab-group class="tab-group-row" style="margin-left: 20px">
                <mat-tab label="Logs">
                  <div class="console-list">
                    <ng-container>
                      <div
                        [ngClass]="{
                          'success-step':
                            steps.executionData?.stepStatus == 'SUCCESS',
                          'failed-step':
                            steps.executionData?.stepStatus == 'FAILURE',
                          'failed-step-section-main':
                            steps.executionData?.stepStatus == 'FAILURE'
                        }"
                      >
                        <div #logContainer class="log">
                          <!-- Only show logs for current step -->
                          <ng-container *ngIf="displayedLogs[steps.id]">
                            <div
                              class="log-step"
                              *ngFor="
                                let log of displayedLogs[steps.id];
                                let logIndex = index
                              "
                            >
                              <div
                                class="log-content"
                                [class.complete]="log.complete"
                              >
                                {{ log.text }}
                                <span
                                  *ngIf="!log.complete"
                                  class="typing-indicator"
                                  >|</span
                                >
                              </div>
                            </div>
                          </ng-container>

                          <!-- Show message when no logs are available -->
                          <div
                            *ngIf="
                              !displayedLogs[steps.id] ||
                              displayedLogs[steps.id].length === 0
                            "
                            class="no-logs-message"
                          >
                            No logs available for this step
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </mat-tab>
              </mat-tab-group>

              <ng-container *ngIf="!stepGroupSteps.empty && steps.stepGroupId">
                <div
                  style="
                    color: white;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    font-size: 16px;
                    padding-left: 20px;
                  "
                >
                  StepGroup Steps
                </div>
                <ng-container
                  *ngFor="
                    let groupStep of stepGroupSteps?.content;
                    let i = index
                  "
                >
                  <mat-expansion-panel-header
                    class="expansion-panel-custom step-group-panel"
                  >
                    <mat-panel-title>
                      <div class="steps-more-info">
                        <div class="sucessc-icon-with-text">
                          <img
                            *ngIf="steps.executionData?.stepStatus == 'SUCCESS'"
                            src="/assets/images/checkmark.svg"
                            alt=""
                          />
                          <img
                            *ngIf="steps.executionData?.stepStatus == 'FAILURE'"
                            src="/assets/images/remove_4.png"
                            alt=""
                          />
                          <img
                            *ngIf="
                              steps.executionData?.stepStatus != 'SUCCESS' &&
                              steps.executionData?.stepStatus != 'FAILURE'
                            "
                            src="/assets/images/pending.svg"
                            alt=""
                          />
                          <span
                            [innerHtml]="groupStep.action"
                            class="fz-14"
                          ></span>
                        </div>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                </ng-container>
              </ng-container>
            </mat-expansion-panel>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div class="card first-card">
    <div class="toggle-button-img">
      <div
        [class.run_result_execution]="Prop.status == 'COMPLETED'"
        class="video-control"
        (click)="redirectToRunDetail()"
        [matTooltip]="'Execution may take a moment to complete.'"
      >
        <mat-icon class="img">open_in_new</mat-icon>
      </div>
      <div mat-button (click)="closeDialog()" class="video-control">
        <mat-icon class="img">close</mat-icon>
      </div>
    </div>
    <div>
      <div
        class="image-container"
        *ngIf="Prop && Prop.imageUrl"
        width="50vw"
        style="height: 70vh; overflow: auto"
      >
        <img [src]="Prop.imageUrl" loading="lazy" width="100%" />
      </div>
      <div class="card mt-10" style="height: 30vh">
        <mat-tab-group class="tab-group-row">
          <mat-tab label="Console">
            <div class="console-wrapper">
              <ng-container *ngIf="Prop?.messageLog">
                <div class="console-line" *ngFor="let log of Prop?.messageLog">
                  <mat-icon
                    class="console-icon"
                    [ngClass]="log.type.toLowerCase()"
                  >
                    {{
                      log.type === "ERROR"
                        ? "error"
                        : log.type === "WARNING"
                        ? "warning"
                        : log.type === "INFO"
                        ? "info"
                        : log.type === "DEBUG"
                        ? "bug_report"
                        : "check_circle"
                    }}
                  </mat-icon>
                  <div class="console-content">
                    <span
                      [ngStyle]="{
                        color:
                          log.type === 'ERROR'
                            ? '#ff8080'
                            : log.type === 'WARNING'
                            ? '#ffd700'
                            : log.type === 'INFO'
                            ? '#87ceeb'
                            : log.type === 'DEBUG'
                            ? '#98fb98'
                            : '#ffffff'
                      }"
                      >{{ log.text }}</span
                    >
                    <span class="location" [title]="log.location">{{
                      log.location
                    }}</span>
                  </div>
                  <span class="console-timestamp">{{
                    log.timestamp | date : "HH:mm:ss.SSS"
                  }}</span>
                </div>

                <div class="console-line" *ngIf="!Prop?.messageLog.length">
                  <div class="console-content">No console logs found</div>
                </div>
              </ng-container>
            </div>
          </mat-tab>
          <mat-tab label="Network">
            <ng-container>
              <div
                class="network"
                style="height: calc(100vh - 100px); padding: 10px"
              >
                <table class="network-table">
                  <thead>
                    <tr>
                      <th class="method-col">Method</th>
                      <th class="url-col">URL</th>
                      <th class="data-col">Request Data</th>
                      <th class="status-col">Status</th>
                    </tr>
                  </thead>
                </table>
                <div class="table-body-container">
                  <table class="network-table">
                    <tbody>
                      <tr *ngFor="let network of Prop?.networkLog">
                        <td class="method-col">{{ network.method }}</td>
                        <td class="url-col" [title]="network.url">
                          {{ network.url }}
                        </td>
                        <td class="data-col">{{ network.reqData || "-" }}</td>
                        <td class="status-col">{{ network.status || "-" }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <h5 *ngIf="!Prop?.networkLog">No network log found!</h5>
                </div>
              </div>
            </ng-container>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>

<div *ngIf="loader" class="execution-start-container">
  <div class="execution-start-content">
    <h1 class="execution-title">Execution Started</h1>
    <div class="animation-container">
      <svg
        class="animated-svg"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="50"
          cy="50"
          r="45"
          stroke="#e0e0e0"
          stroke-width="10"
          fill="none"
        />
        <path
          class="animated-circle"
          d="M5 50a45 45 0 0 1 90 0"
          stroke="#007bff"
          stroke-width="10"
          fill="none"
        />
      </svg>
    </div>
    <p class="execution-message">
      Please wait while we prepare your test execution.
    </p>
    <button class="close-button" mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
